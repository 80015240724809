<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <base-btn
        :color="'white'"
        class="ml-n7"
        :to="`/${this.$i18n.locale}`"
        text
      >
        <base-img
          :src="require('@/assets/logo_only_circle.png')"
          contain
          max-width="60"
          max-height="60"
          width="100%"
        />

        <base-img
          :src="require('@/assets/logo_only_text.png')"
          contain
          max-width="200"
          max-height="200"
          width="100%"
        />
      </base-btn>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(item, i) in $t('AppBar.items')"
            :key="i"
            :exact="item.name === 'Home'"
            :ripple="false"
            active-class="text--secondery"
            class="font-weight-bold"
            min-width="96"
            text
            @click="changePage(item.path)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="$t('AppBar.items')"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',
    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    data: () => ({
      drawer: null,
    }),
    methods: {
      changePage (path) {
        let nextRoute = ''
        var currentUrl = window.location.pathname.split('/')
        if (path.length === 2 && currentUrl.length !== 2) {
          nextRoute = `/${path}/${currentUrl[currentUrl.length - 1]}`
        } else if (path.length === 2 && currentUrl.length === 2) {
          nextRoute = `/${path}`
        } else {
          nextRoute = `/${this.$i18n.locale}/${path}`
        }
        return this.$router.replace({ path: nextRoute })
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
